<script>
import { required, email } from "vuelidate/lib/validators";
//import router from '@/router/routes'
import { ApiClient } from "@/common/http-common"
export default {
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      listDemoUsers: [],
      selectedUser: null,
      hideDemoLogin: false,
      errorMessage:null
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  methods: {
    tryToLogIn() {
      this.submitted = true;
      this.$v.$touch();
      var json = JSON.stringify({ email: this.email.toString(), password: this.password.toString() });
      ApiClient().post("/v1/admin/authentication/login", json).then(function (response) {
        if (response.data.status == true) {
          localStorage.setItem("authentication", response.data.token)
          localStorage.setItem("platformId", 1)
          localStorage.setItem("locale", "mk")
          window.location.href = "/login"
        }
      }).catch(() =>  {
         this.errorMessage = "Invalid credentials"
        }
      )
    },
    getDemoUsers() {
      this.hideDemoLogin = false
      ApiClient().get("/v1/admin/demo/list").then((response) => {
        this.listDemoUsers = response.data.items
        if (this.listDemoUsers.lenght != 0) {
          this.selectedUser = this.listDemoUsers[0]
          this.hideDemoLogin = true
        } else {
          this.hideDemoLogin = false
        }

      }).catch(() => {
        this.hideDemoLogin = false
      }
      )
    },
    loginDemoUser() {
      ApiClient().get("/v1/admin/demo/autentication?id=" + this.selectedUser.id).then((response) => {
        if (response.data.status == true) {
          localStorage.setItem("authentication", response.data.token)
          localStorage.setItem("locale", "mk")
          window.location.href = "/login"
        }
      }).catch(
        function () {

        }
      )
    }
  },
  mounted() {
    this.getDemoUsers()
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-12">
            <div class="authentication-page-content d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-4">
                    <div>
                      <form class="form-horizontal" @submit.prevent="tryToLogIn">
                        <div class="container" style="padding: 0!important;">
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="ri-mail-line auti-custom-input-icon"></i>
                                <label for="email">Email</label>
                                <input type="email" v-model="email" class="form-control" id="email"
                                  placeholder="Enter email" :class="{ 'is-invalid': submitted && $v.email.$error }" />
                                <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                                  <span v-if="!$v.email.required">Email is required.</span>
                                  <span v-if="!$v.email.email">Please enter valid email.</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                <label for="userpassword">Password</label>
                                <input v-model="password" type="password" class="form-control" id="userpassword"
                                  placeholder="Enter password"
                                  :class="{ 'is-invalid': submitted && $v.password.$error }" />
                                <div v-if="submitted && !$v.password.required" class="invalid-feedback">Password is
                                  required.
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12 mb-4" v-if="errorMessage">
                              <p style="color:red;margin:0px!important">{{ errorMessage }}</p>
                              </div>
                            <div class="col-lg-6">
                              <div class="mt text-left">
                                <router-link tag="a" to="/forgot-password" class="text-muted">
                                  <i class="mdi mdi-lock mr-1"></i> Forgot your password?
                                </router-link>
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="mt text-right">
                                <button class="btn btn-primary w-md waves-effect waves-light" type="submit">Log
                                  In</button>
                              </div>
                            </div>
                            <div class="col-lg-12 mt-4 text-center">
                              <p>
                                Don't have an account ?
                                <router-link tag="a" to="/register"
                                  class="font-weight-medium text-primary">Register</router-link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </form>
                      <form class="form-horizontal" @submit.prevent="loginDemoUser" v-if="hideDemoLogin">
                        <div class="form-group auth-form-group-custom mb-4 mt-4">
                          <select class="form-control" v-model="selectedUser">
                            <option v-for="item in listDemoUsers" v-bind:key="item.id" :value="item">{{
                        item.user.first_name }}
                              {{ item.user.last_name }} ({{ item.description }})</option>
                          </select>
                        </div>
                        <div class="mt-4 text-center">
                          <button class="btn btn-primary w-md waves-effect waves-light" type="submit">Log with {{
                        selectedUser.user.first_name }}
                            {{ selectedUser.user.last_name }} ({{ selectedUser.description }})</button>
                        </div>
                      </form>

                      <div class="mt-4 text-center">
                        <p>
                          © 2024 Softpost
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>